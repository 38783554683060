import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"

import "./index.css"

const Aufbau = () => (
  <>
    <Header />
    <SEO title="Seite ist im Aufbau" description="Diese restaura ratisbona Seite ist noch im Aufbau" lang="de"/>
    <h1 className="indexHeadline">Diese Seite ist im Aufbau</h1>
    <Footer />
  </>
)

export default Aufbau
